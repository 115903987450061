@import '~bootstrap-scss/bootstrap.scss';
@import 'color.scss';

.user-img {
  transform: scale(1);
  transition: linear 0.3s;
}
.warning-icon {
  color: #e54f49;
}
.exclude-button {
  display: flex;
  justify-content: center;
}
.flex-modal-right {
  display: flex !important;
  justify-content: flex-end;
  backdrop-filter: blur(7px);
  background: rgba(34, 34, 34, 0.7);
}
.mainContainer {
  padding: 50px !important;
  transition: linear 0.3s;
}

.page-body {
  background-color: #f8f8f8;
  margin-left: 280px;
  transition: linear 0.3s;
}

.subContainer {
  padding: 10px !important;
}
.home-column {
  display: flex;
  height: 156px;
}
.home-text-container {
  margin: 0 0 0 25%;
  display: flex;
  flex-direction: column;
  margin-top: 51px;
  span {
    width: fit-content;
  }
}
.infoContainer {
  padding: 50px !important;
  background-color: #f1f1f1 !important;
  border-radius: 5px !important;
}

.whiteContainer {
  padding: 50px !important;
  background-color: #ffffff !important;
  border-radius: 5px !important;
}
.eventDraftCounter {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
}

.pageTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  margin: 0 10px 20px 0;
  align-items: flex-end;
  transition: linear 0.3s;
}

.pageTitleLogin {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
}

.subTitleMain {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #828282 !important;
}

.secondPageTitle {
  text-decoration: none !important;
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 21px !important;
  line-height: 32px !important;
  color: #222222 !important;
}

.forgotPasswordLabel {
  text-decoration: none !important;
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 42px !important;
  color: #222222 !important;
}

.normalText {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #222222 !important;
}

.subText {
  font-family: 'Poppins', sans-serif;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #222222;
}

.buttonText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #222222 !important;
}
.textButtonsTab {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #050100 !important;
}

.greyNormalText {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #a5a5a5 !important;
}

.auxSucessText {
  color: #7ad81b !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  cursor: pointer !important;
}

.auxGrayText {
  color: #c4c4c4 !important;
  font-weight: 500px !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

// .secondPageTitle {
//   font-family: 'Poppins', sans-serif;
//   font-style: normal !important;
//   font-size: 28px !important;
//   line-height: 42px !important;
//   padding-bottom: 20px !important;
//   text-decoration: underline;
// }

.pageSubTitle {
  font-style: normal !important;
  font-size: 21px !important;
  line-height: 31.5px !important;
  padding-bottom: 10px !important;
}

.infoSubTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 21px !important;
  line-height: 32px !important;
  padding-bottom: 30px !important;
  color: #828282;
}

.fieldLabel {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding-bottom: 15px !important;
  color: #828282 !important;
}

.loginFormLabel {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #828282 !important;
  margin-bottom: 15px !important;
}

.loginFormText {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 21px !important;
  color: #f1f1f1 !important;
}

.checkLabel {
  margin-left: 10px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #a5a5a5 !important;
}

.fieldSpacing {
  padding-bottom: 50px !important;
  // padding-right: 30px !important;
}

// media query for mobile
@media (max-width: '990px') {
  .fieldSpacing {
    padding-bottom: 40px !important;
    // padding-right: 30px !important;
  }
}

.mainButton {
  width: 100% !important;
  height: 64px !important;
  border-radius: 5px !important;
}
.tabButton {
  width: 592px !important;
  height: 64px !important;
  border-radius: 5px !important;
}

.tabButtonActive {
  width: 270px !important;
  height: 64px !important;
  background-color: transparent !important;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  transition: 0.1s;
  border-bottom-style: solid !important;
  border-bottom-width: 3px !important;
  border-bottom-color: black !important;
}

.ordinaryButton {
  background-color: #222222;
  border-radius: 5px;
}

.tabButtonDesactive {
  width: 270px !important;
  height: 64px !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.checkFieldSpacing {
  padding-bottom: 20px !important;
  padding-right: 30px !important;
  color: #828282 !important;
}
.status-container {
  padding-bottom: 50px !important;
  justify-content: flex-start;
  transition: linear 0.3s;
  .eventStatus {
    font-weight: 400 !important;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    margin: 0 7px;
    width: fit-content;
    transition: linear 0.3s;
    svg {
      margin-right: 10px;
      transition: linear 0.3s;
    }
  }
}

.groupButton {
  display: grid !important;
  padding-bottom: 50px !important;
}

.nextPageButton {
  display: flex !important;
  justify-content: end !important;
  padding-top: 30px;
  border-top: solid 1px #d9d9d9;
  align-items: flex-end;
}

.dividerUp {
  margin-bottom: 50px !important ;
}
.dividerDown {
  margin-top: 50px !important ;
  margin-bottom: 50px !important ;
}

.stepContainer {
  height: 66px !important;
  margin-bottom: 59px;
  img {
    max-width: 100%;
    height: auto;
  }
}

.collapseTable {
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 30px;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
}
.collapseTableText {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}

.rotateSvg {
  transform: rotate(-180deg);
  transition: all ease-in-out 0.2s;
}

.iconTable {
  margin-right: 10px;
}

.svgPosition {
  margin-right: 30px;
  margin-top: 30px;
}
//----- Contador de Eventos -----//

.cardHeader {
  height: 165px !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  border: none;
}

// .cardHome {
//   border: none;
//   background: #ffffff !important;
//   border-radius: 1px !important;
//   div {
//     display: flex !important;
//     justify-content: center;
//     align-items: center !important;
//     flex-direction: column;
//   }
//   div + div {
//     border-left: #F1F1F1 ;
//     border-left-style: solid !important;
//   }
// }

.cardHome {
  padding: 0;
  border: none;
  background: #ffffff !important;
  border-radius: 10px !important;
  .row .col:not(:first-child) {
    border-left: 1px solid #f1f1f1;
  }
}

.firstDiv {
  height: 165px !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
  display: inline-block !important;
  border-right-color: #f1f1f1 !important;
}

.lastDiv {
  height: 165px !important;
  width: 264px !important;
  display: inline-block !important;
}

.firstContent {
  margin-top: 30px !important;
  margin-left: 25px !important;
}

.textContent {
  text-align: initial !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: start;
  width: 100%;
  // height: 5rem;
  img {
    margin-right: 10px;
    margin-top: 6px;
  }
}
.count {
  color: #828282 !important;
  margin-left: 27px;
}

.partyCard {
  border-radius: 10px;
  display: inline-block;
  width: 30%;
  min-width: 334px;
  height: 223px;
  margin-right: 1rem;
  > img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

// @media (max-width: 1550px) {
//   .partyCard > img {
//     height: auto;
//     max-width: 100%;
//   }
// }

.actionCard {
  margin-left: 293px !important;
  margin-top: 13px !important;
  justify-content: end !important;
  margin-right: 17px !important;
  position: absolute !important;
}

.partyImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: bottom;
  position: relative;
}
.partyImage2 {
  height: 100%;
}

.partyBlur {
  z-index: 1;
  position: absolute;
  width: 334px;
  height: 120px;

  background: rgba(34, 34, 34, 0.3);
}

//----- Tabela de Eventos -----//

.col-sm-12 {
  padding: 0 0;
}
.container {
  padding: 0 0;
}
.rows {
  display: flex;
  width: 100%;
  height: 83px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #ffffff !important;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}

.linhaDaTabela {
  display: inline-block;
}

.campoFoto {
  min-width: 99px;
  height: 83px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.campoStatus {
  min-width: 4px;
  max-width: 4px;
  height: 83px;
  background-color: #7ad81b;
}
.campoNome {
  min-width: 400px;
  max-width: 400px;
  height: 83px;
  align-items: center;
}
.campoCidade {
  min-width: 180px;
  max-width: 180px;
  height: 83px;
}

.campoData {
  min-width: 182px;
  max-width: 182px;
  height: 83px;
}
.campoAção {
  min-width: 144px;
  max-width: 144px;
  height: 83px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.celulaNome {
  min-width: 236px;
  max-width: 236px;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 22px;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.celulaCidade {
  min-width: 180px;
  max-width: 180px;
  margin-top: 32px;
  // margin-bottom: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
}
.celulaData {
  margin-top: 32px;
  margin-bottom: 32px;
  position: absolute;
}

.celulaAção {
  min-width: 110px;
  max-width: 110px;
  margin-top: 32px;
  margin-bottom: 32px;
  position: absolute;
}

.header {
  display: flex;
  width: 1062px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.headerFoto {
  margin-bottom: 18px;
  width: 99px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.headerStatus {
  width: 4px;
}
.headerNome {
  margin-bottom: 18px;
  width: 400px;
}
.headerCidade {
  margin-bottom: 18px;
  width: 180px;
}

.headerData {
  margin-bottom: 18px;
  width: 182px;
}
.headerAção {
  margin-bottom: 18px;
  width: 144px;
}
.fade {
  backdrop-filter: blur(7px);
  background: rgba(34, 34, 34, 0.7);
  transition: 0.5s;
}
.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}
.modal-body {
  padding: 25px 50px 30px 50px !important;
}
.filter-modal-body {
  padding: 30px 20px 30px 20px !important;
}
.flex-modal-right > div {
  width: 415px !important;
  height: 100% !important;
  margin: 0px 0px !important;
  transition: linear 0.3s;
  transform-origin: right;
  transform: scaleX(0);
  .filter-modal-content {
    height: 100%;
    border-radius: 0;
  }
}
.filter-name {
  font-weight: 400 !important;
  font-size: 21px !important;
  margin-top: 45px !important;
}

.filter-status-name {
  font-weight: 400 !important;
  font-size: 18px !important;
}

.filter-button {
  border: none !important;
  padding-top: 30px;
  align-items: flex-end;
}
.filter-close-buttom {
  display: flex;
  justify-content: end;
  margin: 30px 20px 0 0;
  svg {
    cursor: pointer;
  }
}
.modal-header {
  border: none;
  padding: 40px 50px 20px 50px !important;
}
.modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .modal-close-container {
    top: 15px;
    position: absolute;
    right: 50px;
    z-index: 1;
  }
}
.action-icon {
  cursor: pointer;
}
.botao-cadastro {
  margin-left: 40px;
}
//----- Card de Eventos -----//

.descriptionEvent {
  margin-top: 82px !important;
  border-radius: 10px !important;
  position: absolute !important;
  width: 100%;
  padding: 10px 0 0 25px;
  background-color: transparent;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  bottom: 0;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.nameEvent {
  font-family: 'Poppins', sans-serif;
  color: white !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;

  // padding-top: 11px !important;
  // margin-bottom: 11px !important;
  // padding-left: 25px !important;
  text-align: start !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-container {
  margin: 14px 0 10px 0;
}

.dateEvent {
  font-family: 'Poppins', sans-serif;
  color: white !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;

  margin-bottom: 11px;
  // padding-top: 10px !important;
  // padding-left: 25px !important;
  text-align: start !important;
  height: 18px !important;
  img {
    margin-bottom: 2px;
  }
}

.locationEvent {
  font-family: 'Poppins', sans-serif;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: start;
  height: 18px;
  img {
    margin-bottom: 2px;
  }
}

//----- Login -----//

.loginForm {
  // width: 592px !important;
  height: 64px !important;
  border-radius: 5px !important;
  background-color: #f1f1f1 !important;
  color: #a5a5a5 !important;
  border-color: #f1f1f1 !important;
}

.body-login {
  background: #f8f8f8 !important;
}

.loginCard {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.passwordIcon {
  margin-top: 37px !important;
}

.loginField {
  margin-bottom: 35px !important;
}

.forgotPassword {
  margin-top: 40px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-decoration: underline !important;
  color: #b3140c !important;
}

//----- Card Relatórios -----//

.reportCard {
  width: 182px;
  height: 177px;
  background-color: #ffffff;
  border-radius: 10px;
}

.titleStep {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
}

hr.lineText {
  height: 4px;
  max-width: 20rem;
  position: relative;
  top: 20px;
  border: none;
  height: 2px;
  background: black;
  margin-bottom: 50px;
  margin: auto 0;
  margin-top: -15px;
}

.alert-error {
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e64f49;
}

// ----- Input Geral -----//

.input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #828282;
  margin-bottom: 10px;
}

.button-label-file {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input,
textarea {
  color: black !important;
  box-shadow: none !important;
  &::placeholder {
    color: #a5a5a5 !important;
  }
  &:focus {
    border-color: #050100 !important;
    background-color: #e6e6e6 !important;
    outline: 0 !important;
  }
  &:hover {
    background-color: #d9d9d9;
  }
}

.input-error {
  border-color: #e64f49 !important;
  .input-label {
    color: #e64f49 !important;
    fill: #e64f49 !important;
  }
}

.input-disabled {
  cursor: not-allowed !important;

  label {
    color: #c4c4c4 !important;
  }

  input,
  select,
  textarea,
  div > div {
    background: #ececec !important;
    border-color: #ececec !important;
    color: #c4c4c4 !important;
    border-radius: 5px;
    cursor: not-allowed;
    &:hover {
      background-color: none !important;
      // not selectable
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

input[type='radio']:checked + .label-radio-custom {
  background-color: #222222;
  color: #ffffff;
}

//----- Button Group -----//
.radio-button-gruop {
  display: none;
}

.btn-group {
  .button-group {
    border-color: #a5a5a5;
    color: #a5a5a5;
    background-color: transparent;
    padding: 18px 25px;
  }
  &.input-error * {
    border-color: #e64f49 !important;
  }
}

.button-filter-container {
  display: flex;
  height: fit-content;
}

.btn-group {
  :nth-child(2) {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.input-default {
  height: 62px !important;
  border-radius: 5px !important;
  background-color: #e6e6e6;
  border-color: #e6e6e6 !important;
  &.input-error {
    border-color: #e64f49 !important;
  }
}
// ------ Radio Button ------//
.radio {
  margin: 0.5rem 0;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: transparent;
        border-radius: 100%;
        border: 2px solid darken(#f8f8f8, 25%);
        display: inline-block;
        width: 1.7em;
        height: 1.7em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: #d8413a;
          box-shadow: inset 0 0 0 4px #f8f8f8;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #d8413a;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #f8f8f8;
          border-color: darken(#f8f8f8, 25%);
          background: darken(#f8f8f8, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
.radio-support {
  margin: 0.5rem 0;

  input[type='radio'] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        background: transparent;
        border-radius: 50%;
        border: 1px solid darken(#f8f8f8, 25%);
        display: inline-block;
        width: 1em;
        height: 1em;
        position: relative;
        margin-right: 0.5em;
        cursor: pointer;
        transition: all 250ms ease;
      }
      white-space: nowrap;
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: #d8413a;
          box-shadow: inset 0 0 0 4px #f8f8f8;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #d8413a;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #f8f8f8;
          border-color: darken(#f8f8f8, 25%);
          background: darken(#f8f8f8, 25%);
        }
      }
    }
  }
}

// ----------- Home Responsive ------------
.grid-card-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.container-home {
  margin-right: auto;
  margin-left: auto;
  padding-left: 48px;
  padding-right: 48px;
}

@media (max-width: 1440px) {
  .home-text-container {
    margin: 51px 0 0 15%;
  }
}
@media (max-width: 1100px) {
  // .container-home {
  //   width: 690px;
  // }

  .grid-card-home {
    justify-content: center;
  }
  .home-column {
    justify-content: center;
  }
  .partyCard {
    width: 45%;
    height: 180px;
  }
  .home-text-container {
    margin-left: 0px;
    align-items: center;
  }
  .textContent {
    font-size: 10px;
    line-height: 16px;
    flex-direction: column !important;
    align-items: center;
    img {
      margin: 0 0 8px 0;
    }
    span {
      text-align: center;
      margin-bottom: 10px;
      line-height: 10px;
      height: 24px;
    }
  }
  .count {
    margin-left: 0px;
    span {
      font-size: 12px;
    }
  }
}
@media (max-width: 425px) {
}

@media (max-width: 480px) {
  .home-text-container {
    margin: 25px 0 0 5%;
  }
  .user-img {
    transform: scale(0);
    transition: linear 0.3s;
  }
  .path-flex-container {
    span {
      font-size: 10px !important;
    }
  }
  .profile-name {
    span {
      font-size: 14px !important;
    }

    p {
      font-size: 10px !important;
    }
  }
}
@media (max-width: 479px) {
  .user-img {
    width: none;
  }
}

@media (max-width: 760px) {
  .modal-content {
    height: 100%;
  }
}

// @media (max-width: 960px) {
//   .grid-card-home {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
//     gap: 15px 15px;
//     grid-template-areas:
//       '.'
//       '.'
//       '.'
//       '.'
//       '.'
//       '.';
//     justify-items: stretch;
//   }
// }

figure.partyCard {
  display: inline-block;
  position: relative;
}

.partyCard figcaption {
  width: 100%;
  height: 0%;
}
// ----------- Home Responsive ------------

// media query for mobile container
@media (max-width: '1200px') {
  .event-page-title-container {
    flex-direction: column;
    transition: linear 0.3s;
  }
}
@media (max-width: '550px') {
  .event-page-title {
    margin: 0 10px 0px 0;
  }
  .event-button-filter {
    flex-direction: column-reverse;
  }
  .event-filter-container {
    margin: 30px 0;
    justify-content: space-between;
  }
  .select-label-container {
    margin-left: 0 !important;
  }
  .m-filter {
    margin-left: 30px;
  }
}
@media (max-width: '990px') {
  .status-container {
    justify-content: space-around;
    transition: linear 0.3s;
    .eventStatus {
      transition: linear 0.3s;
    }
    .filterStatus {
      transition: linear 0.3s;
      flex-direction: row !important;
      svg {
        margin-bottom: 0px !important;
        margin-right: 10px !important;
      }
    }
  }
  .mainContainer,
  .content-collapse-main-container {
    padding: 20px !important;
    transition: linear 0.3s;
  }
  .page-body {
    margin-left: 0px;
    transition: linear 0.3s;
  }
  .whiteContainer {
    padding: 30px 20px !important;
  }
  .infoContainer {
    padding: 10px !important;
  }
  .subContainer {
    padding: 10px !important;
  }
  .container-home {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .profile-media {
    display: flex;
    margin-right: 5px;
    transition: linear 0.3s;
    max-width: 140px;
  }
  .profile-name {
    width: 99px;
    transition: linear 0.3s;
    p {
      text-align: center;
      margin-right: 2px;
      transition: linear 0.3s;
    }
  }
  .page-header {
    margin: 0 0;
    transition: linear 0.3s;
    justify-content: space-between;
    .path-container {
      margin-left: 1rem;
    }
  }
  .path-container .path-flex-container .path-main {
    font-weight: 600;
    font-size: 12px;
    width: 150px;
  }
  .event-page-title-container {
    flex-direction: column;
    transition: linear 0.3s;
  }
}
@media (max-width: $media-query-xs) {
  .status-container {
    .eventStatus {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      transition: linear 0.3s;
      svg {
        margin-right: 0px;
        margin-bottom: 10px;
        transition: linear 0.3s;
      }
    }
  }
  .btn {
    &.btn-md {
      font-size: 12px;
      transition: linear 0.3s;
      padding: 0 5px;
    }
  }
  .profile-media {
    margin-right: 0;
  }
  .grid-card-home {
    justify-content: center;
  }
  .verTodos {
    font-size: 14px !important;
    line-height: 1em !important;
    white-space: nowrap;
    margin-left: 5px;
  }
  .textContent {
    span {
      font-size: 9px;
    }
  }
  .home-text-container {
    margin: 25px 0 0 0;
    justify-content: start;
  }
  .partyCard {
    width: 80%;
    height: 180px;
    min-width: 260px;
    margin: 1rem 0 0 8px;
  }
  .dateEvent {
    margin-bottom: 6px;
  }
  .container-home {
    padding: 25px 20px 0 20px !important;
  }
  .home-column {
    padding: 0 !important;
  }
  .count {
    margin-top: 18px;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.border-bottom-title {
  border-bottom: 1px solid #222222;
  font-weight: 700;
}
.border-bottom-title-500 {
  border-bottom: 1px solid #222222;
  font-weight: 500;
  padding-right: 10px;
  width: fit-content;
  padding-right: 30px;
}

.subtitle {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
}

.collapse-title-button {
  margin-right: 15px;
  border: 0;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: rgb(34, 34, 34);
  color: #ffffff !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  height: 34px;
  padding: 8px 15px;
}

.text-area-component {
  min-height: 62px !important;
  border-radius: 5px !important;
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
  &.input-error {
    border-color: #e64f49 !important;
  }
}

.fw-700 {
  font-weight: 700;
}

.fw-400 {
  font-weight: 400;
}

.circle-notification {
  height: 17px;
  width: 17px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: absolute;

  top: -5px;
  right: 19px;
  &__number {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 12px;
    color: white;
  }
}
.pointerSvg {
  cursor: pointer;
}
.sm-icon {
  transform: scale(0.8);
}
.arrow-left {
  position: relative;
  top: 3px;
}

.badge-custom {
  margin-left: 11px;
  top: 25px;
  min-width: 13px;
}

.copy-icon {
  margin-left: 10px;
  transform: scale(0.8);
}
.voucher-code-row {
  display: flex;
  align-items: center;
  //font-weight: 400 !important;
}
body
  > div:nth-child(4)
  > div
  > div.modal.fade.show
  > div
  > div
  > div.modal__with-container.modal-body
  > div
  > div
  > div:nth-child(3)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  font-weight: 700;
  color: #222;
}
.container-event {
  max-width: 667px;
}

.footer-register-event {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  :first-child {
    margin-right: 27px;
  }
}

.disabled-content {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}

.border-bottom-divider {
  border: 1px solid #d9d9d9;
  width: calc(100% + 60px);
  margin-left: -30px;
}

.content-collapse-title-container {
  display: flex;
  width: 80%;
  .content-collapse-title {
    color: #d8413a;
    font-size: 16px;
    margin-right: 3px;
    margin-bottom: 25px;
  }
  .content-collapse-title-index {
    font-weight: 300;
  }
  .content-collapse-title-name {
    font-weight: 500;
    max-width: 70%;
  }
}

.collapseTertiary {
  width: fit-content;
}

.content-collapse-title-flex {
  display: flex;
  max-width: 61%;
  justify-content: space-between;
}
.content-collapse-icon-container {
  display: flex;
  margin-left: 10px;
}

.content-collapse-content-container {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px !important;
  display: flex;
  flex-direction: column;
  :nth-child(1) {
    color: #a5a5a5;
    margin-bottom: 7px;
  }
  :nth-child(2) {
    color: #222222;
  }
}

@media screen and (max-width: 990px) {
  .content-collapse-title-flex {
    max-width: 100%;
  }
}

.secondary-table-title {
  color: #d8413a;
  &.name {
    font-weight: 500;
  }
}

.collapse-disabled {
  cursor: not-allowed !important;
  background-color: #ececec !important;
  pointer-events: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.input-action-disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disable-text {
  color: #c4c4c4 !important;
}

.buttonGroup-disabled {
  background-color: transparent !important;
  border: 1px solid #d9d9d9 !important;
  color: #c4c4c4 !important;
}

.card-container {
  margin-bottom: 0 !important;
  padding: 30px;
  height: fit-content;
}

.card-ligth-color {
  background: #fff;
  border-radius: 5px;
  padding: 50px 15px;
}

.light-text {
  font-weight: 300 !important;
}

.action-text {
  color: #c4c4c4;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    color: #222222 !important;
    fill: #222222 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-area-comment {
  width: 100%;
  background: transparent;
  color: #828282 !important;
  border: 0;
  resize: none;
}

.text-area-comment:focus {
  background-color: transparent;
}

.text-area-comment:hover {
  background-color: transparent;
}

.report-menu-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-column-gap: 38px;
  grid-row-gap: 30px;
  .report-menu-card {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
    white-space: nowrap;
    min-width: 182px;
    width: fit-content;
    align-items: center;
    padding: 26px 30px;
    border-radius: 10px;
    max-height: 76px;
    border: 0px solid #f1f1f1;
    cursor: pointer;
    &:hover {
      transition: ease 0.3s;
      border: 1px solid #f1f1f1;
    }
  }
}

.report-footer {
  position: fixed;
  bottom: 0px;
  right: 0;
  padding-left: 302px !important;
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.05);
  border: none;
  z-index: 9;
  padding: 22px 21px;
  transition: all 0.3s ease-in-out;
  @media (max-width: 990px) {
    padding-left: 20px !important;
    transition: all 0.3s ease-in-out;
  }
  overflow: auto;
}

.report-summary {
  margin: 0 10px;
}

.circle-separator {
  vertical-align: middle;
  font-size: 12px;
  color: #e6e6e6;
}

.collapseTableGrid {
  display: grid;
  column-gap: 70px;
}

#pageWrapper > div.page-body-wrapper.sidebar-icon > div.page-body > div > div.card > div {
  min-width: 1040px;
}

.text-elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-align-center {
  text-align: center;
}

.text-bold-700 {
  font-weight: 700;
}

.text-darkgray {
  color: #828282;
}
.text-darkgray-light {
  font-weight: 300;
  font-size: 16px;
  color: #828282;
}
.text-darkgray-regular {
  font-weight: 400;
  font-size: 16px;
  color: #828282;
}
.text-darkgray-500 {
  font-weight: 500;
  font-size: 16px;
  color: #828282;
}
.text-darkgray-700 {
  font-weight: 700;
  font-size: 16px;
  color: #828282;
}
.text-small-darkgray-regular {
  font-weight: 400;
  font-size: 12px;
  color: #828282;
}
.text-small-darkgray-700 {
  font-weight: 700;
  font-size: 12px;
  color: #828282;
}

.text-black-regular-large {
  font-weight: 400;
  font-size: 21px;
  color: #222222;
}
.text-black-regular {
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}

.text-black-light {
  font-weight: 300;
  font-size: 16px;
  color: #222222;
}

.text-small-black-regular {
  font-weight: 400;
  font-size: 12px;
  color: #222;
}

.text-small-black-light {
  font-weight: 300;
  font-size: 12px;
  color: #222;
}
.text-small-black-600 {
  font-weight: 600;
  font-size: 12px;
  color: #222;
}
.text-600-gray-regular {
  font-weight: 600;
  font-size: 12px;
  color: #a5a5a5;
}
.text-small-gray-regular {
  font-weight: 400;
  font-size: 12px;
  color: #a5a5a5;
}

.noWrap {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-no-border {
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}
.collapse-no-border {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}
.collapseSecondary {
  padding-bottom: 0 !important;
}

.width100 {
  width: 100px;
}

.width120 {
  width: 120px;
}

.width150 {
  width: 150px;
}

.width170 {
  width: 170px;
}

.width200 {
  width: 200px;
}
.width300 {
  width: 300px;
}
.max-width100 {
  max-width: 100px;
}

.max-width200 {
  max-width: 200px;
}
.max-width300 {
  max-width: 300px;
}

.collapseTable-container {
  width: 100%;
  background-color: #ffffff;
  padding: 32px 36px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}

.containerTitle {
  display: grid;
  row-gap: 30px;
  padding: 18px 33px;
}

.collapseTable-title {
  padding: 18px 30px;
  font-weight: 400;
  font-size: 16px;
}

.containerArrow {
  justify-self: end;
  padding: 0 33px;
}
.containerTitle {
  display: grid;
  row-gap: 30px;
  padding: 30px 33px;
  justify-items: center;
}
.collapseTableContent {
  display: grid;
  row-gap: 30px;
  padding: 30px 33px;
}
.contentTitle {
  display: grid;
  padding: 30px 0px;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 12px;
}

.contentData {
  font-weight: 400;
  font-size: 12px;
  color: #222222;
}

.containerArrow {
  justify-self: end;
  padding: 0 33px;
}

.collapseTable--open {
  height: 100%;
  position: relative;
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0%);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  z-index: 1;
}

.collapseTable--hidden {
  visibility: hidden;
  opacity: 0;
  position: relative;
  transform: translate(0px, -10%);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  z-index: -1;
}

.customTableScroll {
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgb(200, 200, 200);
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(170, 170, 170);
    border-radius: 10px;
  }
}

.saleLinkGenerator {
  float: right;
  transition: all 0.3s ease;
  @media screen and (max-width: 1500px) {
    float: none;
    padding: 0 15px;
    transition: all 0.3s ease;
  }
}

@media print {
  .sidebar-container {
    display: none;
  }
}
